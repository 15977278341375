<template>
   <v-dialog
    v-model="show"
    persistent
    max-width="600px"
    scrollable
    content-class="content-scroll"
  >
    <v-card tile>
      <v-card-title class="d-flex justify-space-between blue-grey lighten-5 pr-1">
        <div class="d-flex aling-center">
          <v-icon left color="primary" size="22">mdi-timeline-text-outline</v-icon>
          <span class="display-1 primary--text font-weight-medium">Descripción del Log</span>
        </div>
        <v-btn icon @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="log-details content-scroll px-0">
        <v-row>
          <v-col cols="12" md="5">
            <v-icon small left color="blue-grey">mdi-checkbox-marked-circle-outline</v-icon>
            <span class="caption blue-grey--text text--darken-3">Código</span>
          </v-col>
          <v-col cols="12" md="7">
            <span class="font-weight-bold primary--text" v-text="log.codigo" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="5">
            <v-icon small left color="blue-grey">mdi-checkbox-marked-circle-outline</v-icon>
            <span class="caption blue-grey--text text--darken-3">Mensaje</span>
          </v-col>
          <v-col cols="12" md="7">
            <span class="font-weight-bold primary--text" v-text="log.mensaje" />
          </v-col>
        </v-row>
        <v-row v-if="log.controlador">
          <v-col cols="12" md="5">
            <v-icon small left color="blue-grey">mdi-checkbox-marked-circle-outline</v-icon>
            <span class="caption blue-grey--text text--darken-3">Controlador</span>
          </v-col>
          <v-col cols="12" md="7">
            <span class="font-weight-bold primary--text" v-text="log.controlador" />
          </v-col>
        </v-row>
        <v-row v-if="log.linea">
          <v-col cols="12" md="5">
            <v-icon small left color="blue-grey">mdi-checkbox-marked-circle-outline</v-icon>
            <span class="caption blue-grey--text text--darken-3">Linea</span>
          </v-col>
          <v-col cols="12" md="7">
            <span class="font-weight-bold primary--text" v-text="log.linea" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="5">
            <v-icon small left color="blue-grey">mdi-checkbox-marked-circle-outline</v-icon>
            <span class="caption blue-grey--text text--darken-3">Tabla BD</span>
          </v-col>
          <v-col cols="12" md="7">
            <span class="font-weight-bold primary--text" v-text="log.tabla" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="5">
            <v-icon small left color="blue-grey">mdi-checkbox-marked-circle-outline</v-icon>
            <span class="caption blue-grey--text text--darken-3">Estatus</span>
          </v-col>
          <v-col cols="12" md="7">
            <span class="font-weight-bold primary--text" v-text="log.status" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="5">
            <v-icon small left color="blue-grey">mdi-checkbox-marked-circle-outline</v-icon>
            <span class="caption blue-grey--text text--darken-3">Usuario</span>
          </v-col>
          <v-col cols="12" md="7">
            <span class="font-weight-bold primary--text" v-text="log.usuario" />
          </v-col>
        </v-row>
        <v-row v-if="log.ip">
          <v-col cols="12" md="5">
            <v-icon small left color="blue-grey">mdi-checkbox-marked-circle-outline</v-icon>
            <span class="caption blue-grey--text text--darken-3">Dirección IP</span>
          </v-col>
          <v-col cols="12" md="7">
            <span class="font-weight-bold primary--text" v-text="log.ip" />
          </v-col>
        </v-row>
        <v-row v-if="log.dispositivo">
          <v-col cols="12" md="5">
            <v-icon small left color="blue-grey">mdi-checkbox-marked-circle-outline</v-icon>
            <span class="caption blue-grey--text text--darken-3">Dispositivo</span>
          </v-col>
          <v-col cols="12" md="7">
            <span class="font-weight-bold primary--text" v-text="log.dispositivo" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="5">
            <v-icon small left color="blue-grey">mdi-checkbox-marked-circle-outline</v-icon>
            <span class="caption blue-grey--text text--darken-3">Tipo</span>
          </v-col>
          <v-col cols="12" md="7">
            <span class="font-weight-bold primary--text" v-text="log.tipo" />
          </v-col>
        </v-row>
        <v-row v-if="log.ambiente">
          <v-col cols="12" md="5">
            <v-icon small left color="blue-grey">mdi-checkbox-marked-circle-outline</v-icon>
            <span class="caption blue-grey--text text--darken-3">Ambiente</span>
          </v-col>
          <v-col cols="12" md="7">
            <span class="font-weight-bold primary--text" v-text="log.ambiente" />
          </v-col>
        </v-row>
        <v-row v-if="log.fecha_recibido">
          <v-col cols="12" md="5">
            <v-icon small left color="blue-grey">mdi-checkbox-marked-circle-outline</v-icon>
            <span class="caption blue-grey--text text--darken-3">Fecha Recibido</span>
          </v-col>
          <v-col cols="12" md="7">
            <span class="font-weight-bold primary--text">{{log.fecha_recibido | smartDate}}</span>
          </v-col>
        </v-row>
        <v-row v-if="log.fecha_recibido">
          <v-col cols="12" md="5">
            <v-icon small left color="blue-grey">mdi-checkbox-marked-circle-outline</v-icon>
            <span class="caption blue-grey--text text--darken-3">Hora Recibido</span>
          </v-col>
          <v-col cols="12" md="7">
            <span class="font-weight-bold primary--text">{{log.fecha_recibido | hora}}</span>
          </v-col>
        </v-row>
        <v-row v-if="log.fecha_evento">
          <v-col cols="12" md="5">
            <v-icon small left color="blue-grey">mdi-checkbox-marked-circle-outline</v-icon>
            <span class="caption blue-grey--text text--darken-3">Fecha Evento</span>
          </v-col>
          <v-col cols="12" md="7">
            <span class="font-weight-bold primary--text">{{log.fecha_evento | smartDate}}</span>
          </v-col>
        </v-row>
        <v-row v-if="log.fecha_evento">
          <v-col cols="12" md="5">
            <v-icon small left color="blue-grey">mdi-checkbox-marked-circle-outline</v-icon>
            <span class="caption blue-grey--text text--darken-3">Hora Evento</span>
          </v-col>
          <v-col cols="12" md="7">
            <span class="font-weight-bold primary--text">{{log.fecha_evento | hora}}</span>
          </v-col>
        </v-row>
        <v-row v-if="log.fecha">
          <v-col cols="12" md="5">
            <v-icon small left color="blue-grey">mdi-checkbox-marked-circle-outline</v-icon>
            <span class="caption blue-grey--text text--darken-3">Fecha</span>
          </v-col>
          <v-col cols="12" md="7">
            <span class="font-weight-bold primary--text">{{log.fecha | smartDate }}</span>
          </v-col>
        </v-row>
        <v-row v-if="log.fecha">
          <v-col cols="12" md="5">
            <v-icon small left color="blue-grey">mdi-checkbox-marked-circle-outline</v-icon>
            <span class="caption blue-grey--text text--darken-3">Hora</span>
          </v-col>
          <v-col cols="12" md="7">
            <span class="font-weight-bold primary--text">{{log.fecha | hora }}</span>
          </v-col>
        </v-row>
      </v-card-text>
      <!-- <v-card-actions class="d-flex justify-end">
          <v-btn
            tile
            color="primary"
            class="px-8"
            small
            @click="show = false"
          >
            <v-icon dark left>mdi-location-exit</v-icon>
            Salir
          </v-btn>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
export default {
  name: 'DescripcionLog',
  props:{
    value: Boolean,
    log: {
      type: Object,
      default: () => ({})
    }
  },
  data(){
    return {
      show: this.value
    }
  },
  watch: {
    value(val, oldVal){
      if(val === oldVal) return;

      this.show = val;
    },
    show(val, oldVal){
      if(val === oldVal) return;

      this.$emit('input', val);
    }
  },
  filters:{
    hora(fecha){
      return moment(fecha).format('hh:mm:ss a');
    }
  }
}
</script>
<style lang="sass" scoped>
.log-details .row
  margin: 0 !important
  &.col
      display: flex
      align-items: center
.log-details .row:nth-of-type(odd) 
  background-color: #FAFAFA
</style>